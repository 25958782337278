import React, { useState } from "react";

function PasswordInput({ className, name, text, value, onChange, onBlur }) {
  const [show, setShow] = useState(false);

  const handleShowPass = () => {
    setShow((prevState) => !prevState);
  };

  return (
    <div className={`${className ?? ""} passwordInput`}>
      <input
        name={name ?? ""}
        className="Input"
        type={show ? "text" : "password"}
        placeholder={text}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {!show ? (
        <img
          src="/imgs/eye-off.svg"
          alt="hide password"
          className="passwordHideBtn"
          onClick={handleShowPass}
        />
      ) : (
        <img
          src="/imgs/eye-on.svg"
          alt="hide password"
          className="passwordHideBtn"
          onClick={handleShowPass}
        />
      )}
    </div>
  );
}

export default PasswordInput;
