import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { Delete, Put, get, post } from "utils/api";

export const getThreadsList = createAsyncThunk(
  "threads/getThreadsList",
  async () => {
    const response = await get(`/threads`);
    return response;
  }
);

export const createThread = createAsyncThunk(
  "threads/createThread",
  async (value) => {
    const response = await post(`/thread`, {
      threadName: value,
    });
    const moment = require("moment");
    const date = moment(response.created_at * 1000);
    response.created_at = date.utc().format();
    response.thread_name = value;
    response.metadata = "";
    return response;
  }
);

export const renameThread = createAsyncThunk(
  "threads/renameThread",
  async (data) => {
    const response = await Put(`/thread`, { ...data });
    return response;
  }
);

export const deleteThread = createAsyncThunk(
  "threads/deleteThread",
  async (threadId) => {
    const response = await Delete(`/thread/${threadId}`);
    return response;
  }
);

export const threadSlice = createSlice({
  name: "threadsData",
  initialState: {
    threadsList: [],
    inputValue: {},
    activeThreadId: "",
    oldThreadId: "",
    status: "",
  },

  reducers: {
    setActiveThread: (state, action) => {
      if (state.activeThreadId !== action.payload) {
        state.oldThreadId = state.activeThreadId;
        state.activeThreadId = action.payload;
      }
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    incrementThreadMessage: (state, action) => {
      for (let i = 0; i < state.threadsList.length; i++) {
        if (state.threadsList[i].thread_id === state.activeThreadId) {
          if (!state.threadsList[i].messages) {
            state.threadsList[i].messages = 1;
          } else {
            state.threadsList[i].messages += 1;
          }
        }
      }
    },
    setThreadInputValue: (state, action) => {
      if (
        state.oldThreadId &&
        action.payload.inputValue !== "" &&
        action.payload.isOldThread
      ) {
        state.inputValue[state.oldThreadId] = action.payload.inputValue;
      }
      if (action.payload.inputValue === "" && state.oldThreadId) {
        delete state.inputValue[
          action.payload.isOldThread ? state.oldThreadId : state.activeThreadId
        ];
      }
    },
    clearThreads: (state, action) => {
      state.activeThreadId = "";
      state.oldThreadId = "";
      state.threadsList = [];
      state.inputValue = {};
    },
    setEmptyThread: (state, action) => {
      if (action.payload === "delete") {
        state.threadsList = [];
      } else {
        let today = +new Date() / 1000;
        state.threadsList = [];
        state.threadsList.push({
          id: 1,
          thread_name: "Новый диалог",
          thread_id: "thread_1",
          object: "thread",
          created_at: today,
          metadata: "",
          messages: 0,
        });
      }
    },
    updateThreadDate: (state, action) => {
      for (let i = 0; i < state.threadsList.length; i++) {
        const thread = state.threadsList[i];
        if (thread.thread_id === action.payload.thread_id) {
          thread.created_at = moment().utc().format();
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getThreadsList.pending, (state, action) => {
      state.status = "threadGetting";
    });
    builder.addCase(getThreadsList.fulfilled, (state, action) => {
      state.status = "";

      if (Array.isArray(action.payload) && state?.threadsList[0]?.id !== 1) {
        state.threadsList = action.payload;
        return;
      }
    });
    builder.addCase(createThread.pending, (state, action) => {
      state.status = "threadCreating";
    });
    builder.addCase(createThread.fulfilled, (state, action) => {
      state.oldThreadId = state.activeThreadId;
      // state.threadsList.push(action.payload);
      state.threadsList = [action.payload, ...state.threadsList];
      state.status = "";
    });
    builder.addCase(renameThread.pending, (state, action) => {
      state.status = "threadRenaming";
    });
    builder.addCase(renameThread.fulfilled, (state, action) => {
      state.status = "";
      for (const item of state.threadsList) {
        if (item.thread_id === action.payload?.thread_id) {
          item.thread_name = action.payload.thread_name;
        }
      }
    });
    builder.addCase(deleteThread.pending, (state, action) => {
      state.status = "threadDeleting";
    });
    builder.addCase(deleteThread.fulfilled, (state, action) => {
      state.status = "";
      state.oldThreadId = state.activeThreadId;
      if (state.threadsList.length > 1) {
        state.threadsList = state.threadsList.filter((thread, index) => {
          return thread.thread_id !== action.payload.id;
        });
      } else {
        state.activeThreadId = "";
        state.threadsList = [];
      }
    });
  },
});

export const {
  setActiveThread,
  setStatus,
  incrementThreadMessage,
  setThreadInputValue,
  clearThreads,
  setEmptyThread,
  updateThreadDate,
} = threadSlice.actions;

export default threadSlice.reducer;
