import ErrorSvg from "components/common/svgs/ErrorSvg";
import InfoSvg from "components/common/svgs/InfoSvg";
import SuccessSvg from "components/common/svgs/SuccessSvg";
import { toast } from "react-toastify";

export const showError = (textMsg) => {
  const resText = textMsg?.response?.data?.error;
  const errorMsg =
    textMsg?.code === "ERR_NETWORK" || textMsg?.code === "ERR_BAD_RESPONSE"
      ? "Сервис недоступен"
      : resText || textMsg?.message || textMsg;
  const errorMsgCapitalized =
    errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
  toast.error(errorMsgCapitalized, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    icon: () => <ErrorSvg color="#fff" />,
  });
};

export const showInfo = (textMsg) => {
  toast.info(textMsg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    icon: () => <InfoSvg color="#fff" />,
  });
};

export const showSuccess = (textMsg) => {
  toast.success(textMsg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    icon: () => <SuccessSvg color="#fff" size="small" />,
  });
};
