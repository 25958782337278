import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  authWithCode,
  clearUserData,
  oauthWithCode,
} from "store/slices/userSlice";
import { decodeToken } from "./decodeToken";
import { openModal } from "store/slices/systemSlice";
import { setEmptyThread } from "store/slices/threadSlice";

const PrivateRoutes = ({ neededRol }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let rol = "";
  const userToken = Cookies.get("analyticsToken");
  const params = new URLSearchParams(location.search);
  const codeParam = params.get("code");

  if (userToken) {
    try {
      const userTokenParsed = decodeToken(userToken);
      rol = userTokenParsed.rol;
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        dispatch(clearUserData());
      }, 10);
    }
  }

  if (!userToken) {
    dispatch(setEmptyThread());

    if (codeParam) {
      if (location.pathname.includes("login")) {
        dispatch(
          oauthWithCode({
            code: codeParam,
            type: location.pathname.split("/")[1],
          })
        ).then(() => {
          navigate("/");
        });
      }

      if (location.pathname === "/verify") {
        dispatch(authWithCode({ code: codeParam })).then(() => {
          navigate("/");
        });
      }

      if (location.pathname === "/reset") {
        dispatch(openModal("ResetPass"));
      }
    } else {
      dispatch(openModal("Authorization"));
    }

    // return <Navigate to="/" replace />;
  }

  return neededRol.includes(rol) ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoutes;
