import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { clearUserData } from "../../store/slices/userSlice";
import { closeModal } from "store/slices/systemSlice";
import { clearMessages } from "store/slices/chatSlice";
import { clearThreads } from "store/slices/threadSlice";

function AccountExit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    document.body.style.overflow = "";
    navigate("/");
    dispatch(closeModal());

    dispatch(clearUserData());
    dispatch(clearMessages());
    dispatch(clearThreads());
  };

  const onClose = () => {
    document.body.style.overflow = "";
    dispatch(closeModal());
  };

  return (
    <div className="Logout">
      <div className="Logout__title">Выйти из профиля</div>
      <div className="Logout__text text">Вы точно хотите выйти?</div>
      <div className="Logout__btns">
        <button className="Logout__btn SolidBtn" onClick={logOut}>
          Да, выйти
        </button>
        <button className="Logout__btn OutlineBtn" onClick={onClose}>
          Остаться
        </button>
      </div>
    </div>
  );
}

export default AccountExit;
