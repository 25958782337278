import React, { useEffect, useState } from "react";
import PasswordInput from "components/common/passwordInput";
import "./Authorization.css";
import { auth, goToAuthLink } from "../../../store/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "store/slices/systemSlice";
import { useNavigate } from "react-router-dom";
import { passwordValidation } from "utils/passwordValidation";
import { setEmptyThread } from "store/slices/threadSlice";

export const AuthMethod = ({ method, disableBtn, setDisableBtn }) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getOauthLink = (type) => {
    dispatch(goToAuthLink(type)).then((res) => {
      setTimeout(() => {
        setLoading(false);
        setDisableBtn(false);
      }, 1500);
    });
    setLoading(true);
    setDisableBtn(true);
  };

  return (
    <button
      disabled={isLoading || disableBtn}
      className="Auth__method OutlineBtn"
      onClick={() => getOauthLink(method)}
    >
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <img src={`/imgs/${method}Logo.svg`} alt={`${method} logo`} />
      )}
    </button>
  );
};

const Authorization = () => {
  const status = useSelector((state) => state.userData.status);
  const [disableBtn, setDisableBtn] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("Поле не может быть пустым");
  const [emailDirty, setEmailDirty] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(
    "Поле не может быть пустым"
  );
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (emailError || passwordError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [emailError, passwordError]);

  const isLoading =
    status === "authorization" || status === "oauthAuthorization";

  const submitAuthForm = (e) => {
    e.preventDefault();
    dispatch(auth({ email, password })).then((res) => {
      if (!res.payload.code) {
        navigate("/");
      }
    });
  };

  const authData = ["vk", "google", "yandex", "mailru"];

  const getDocs = () => {
    window.open("/docs/Политика_обработки_персональных_данных.pdf", "_blank");
    window.open("/docs/Соглашение_об_использовании_cookies.pdf", "_blank");
  };

  const emailHandler = (e) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    setEmail(e.target.value);

    if (!regex.test(String(e.target.value).toLowerCase())) {
      setEmailError("Некорректный email");
    } else {
      setEmailError("");
    }
  };

  const onPasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError();
    passwordValidation(e, setPasswordError, false);
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "email":
        setEmailDirty(true);
        break;
      case "password":
        setPasswordDirty(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="Auth">
      <div className="Auth__wrap">
        <div className="Auth__body">
          <div className="Auth__title">Авторизируйся чтобы решить задачу</div>
          <div className="Auth__subTitle">
            Авторизация нужна чтобы сохранить историю и безопасность ваших
            запросов, авторизоваться можно любым удобным способом
          </div>
          <div className="Auth__methods">
            {authData.map((method, index) => {
              return (
                <AuthMethod
                  method={method}
                  key={index}
                  setDisableBtn={setDisableBtn}
                  disableBtn={disableBtn}
                />
              );
            })}
          </div>
          <div className="Auth__separatorBlock">
            <div className="Auth__separator"></div>
            <div className="Auth__separator_text">или</div>
            <div className="Auth__separator"></div>
          </div>
          <form className="Auth__inputBlock" onSubmit={submitAuthForm}>
            <div className="Auth__input">
              <input
                className={`Input ${emailDirty && emailError ? "Error" : ""}`}
                type="email"
                placeholder="Логин"
                value={email}
                onChange={emailHandler}
                onBlur={blurHandler}
                name="email"
              />
              {emailDirty && emailError && (
                <span className="Validation__Error">{emailError}</span>
              )}
            </div>
            <div className="Auth__passwordWrap">
              <PasswordInput
                name={"password"}
                text={"Пароль"}
                className={
                  passwordDirty && passwordError
                    ? "Auth__input Error"
                    : "Auth__input"
                }
                value={password}
                onChange={(e) => onPasswordChange(e)}
                onBlur={blurHandler}
              />
              {passwordDirty && passwordError && (
                <span className="Validation__Error">{passwordError}</span>
              )}
              <div className="Auth__resetPass">
                <span onClick={() => dispatch(openModal("ForgotPass"))}>
                  Забыли пароль?
                </span>
              </div>
            </div>
            <button
              className="Auth__inputBtn SolidBtn"
              type="submit"
              disabled={!formValid || isLoading || disableBtn}
            >
              {isLoading ? <div className="loader"></div> : "Войти"}
            </button>
          </form>
          <div className="Auth__desc">
            Авторизуясь, я соглашаюсь с{" "}
            <span className="Auth__desc_link link" onClick={getDocs}>
              Правилами и ограничениями
            </span>
          </div>
          <div className="Auth__authbtn">
            Еще не в системе?{" "}
            <span onClick={() => dispatch(openModal("Registration"))}>
              Зарегистрироваться
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
