import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { get, post } from "utils/api";
import { showSuccess } from "utils";
import { closeModal } from "./systemSlice";
import ArrayCodes from "ArrayCodes.json";
import { decodeToken } from "utils/decodeToken";

export const sentPayment = createAsyncThunk(
  "user/sentPayment",
  async ({ tariff_plan }, { getState }) => {
    const state = getState();
    const data = {
      email: state.userData.adr,
      tariff_plan: tariff_plan,
    };
    const response = await post(`/payment`, data);

    return response;
  }
);

export const completeGuide = createAsyncThunk(
  "user/completeGuide",
  async () => {
    const response = await get(`/ui/complete`);
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async ({ data }) => {
    const response = await post(`/password/change`, data);
    if (!response.error && !response.code) {
      showSuccess(response.message);
    }

    return response;
  }
);

export const goToAuthLink = createAsyncThunk(
  "user/goToAuthLink",
  async (type) => {
    const response = await get(`/oauth/${type}/url`);
    if (response) {
      const redirectUrl = response?.URL;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  }
);

export const oauthWithCode = createAsyncThunk(
  "user/oauthWithCode",
  async ({ code, type }, { dispatch }) => {
    if (Cookies.get("analyticsToken")) {
      showSuccess(`Пользователь уже авторизован`);
      return;
    }
    const response = await get(`/oauth/${type}/login`, `code=${code}`);
    const userToken = response?.access_token;

    if (userToken) {
      Cookies.set("analyticsToken", userToken);
      const userTokenParsed = decodeToken(userToken);
      dispatch(updateUserData(userTokenParsed));
      dispatch(closeModal());
      window.ym(97182671, "reachGoal", "auth");
      showSuccess(`Пользователь ${userTokenParsed.adr} успешно авторизован`);
    }
    return response;
  }
);

export const setProfileInfo = createAsyncThunk(
  "user/setProfileInfo",
  async (value, { getState, dispatch }) => {
    const state = getState();
    const data = {
      send_messages: state.userData.send_messages,
      telegram: state.userData.telegram,
      font_size: state.userData.font_size,
      name: state.userData.fio,
      nickname: state.userData.nickname,
    };
    dispatch(setStatus(Object.keys(value)[0] + " loading"));
    const response = await post(`/user/profile`, { ...data, ...value });
    if (response !== null) {
      return { ...data };
    }
    return { ...data, ...value };
  }
);

export const getProfileInfo = createAsyncThunk(
  "user/getProfileInfo",
  async () => {
    const response = await get(`/user/profile`);
    return response;
  }
);

export const getProfileStats = createAsyncThunk(
  "user/getProfileStats",
  async () => {
    const response = await get(`/profile/messages/stats`);
    return response;
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async ({ email }) => {
    const response = await post(`/password/reset`, { email: email });
    if (!response.error && !response.code) {
      let result = ArrayCodes.find((item) => item.code === response.code);
      showSuccess(result?.ru ?? response.message);
    }
    return;
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async ({ data }) => {
    const response = await post(`/password/reset/validate`, data);
    if (!response.error && !response.code) {
      let result = ArrayCodes.find((item) => item.code === response.code);
      showSuccess(result?.ru ?? response.message);
    }
    return response;
  }
);

export const authWithCode = createAsyncThunk(
  "user/authWithCode",
  async ({ code }, { dispatch }) => {
    if (Cookies.get("analyticsToken")) {
      showSuccess(`Пользователь уже авторизован`);
      return;
    }
    const response = await get(`/email/verify`, `code=${code}`);
    const userToken = response?.access_token;

    if (userToken) {
      Cookies.set("analyticsToken", userToken);
      const userTokenParsed = decodeToken(userToken);
      dispatch(updateUserData(userTokenParsed));
      dispatch(closeModal());
      window.ym(97182671, "reachGoal", "auth");
      showSuccess(`Пользователь ${userTokenParsed.adr} успешно авторизован`);
    }
    return response;
  }
);

export const auth = createAsyncThunk(
  "user/auth",
  async ({ email, password }, { dispatch }) => {
    const data = { login: email, password: password };
    const response = await post(`/login`, data);
    const userToken = response?.access_token;
    if (userToken) {
      Cookies.set("analyticsToken", userToken);
      const userTokenParsed = decodeToken(userToken);
      dispatch(updateUserData(userTokenParsed));
      dispatch(closeModal());
      window.ym(97182671, "reachGoal", "auth");
      showSuccess(`Пользователь ${email} успешно авторизован`);
    }
    return response;
  }
);

export const register = createAsyncThunk(
  "user/register",
  async ({ data }, { dispatch }) => {
    const response = await post(`/create`, data);
    const userToken = response?.access_token;

    if (userToken) {
      Cookies.set("analyticsToken", userToken);
      const userTokenParsed = decodeToken(userToken);
      dispatch(updateUserData(userTokenParsed));
    } else {
      dispatch(updateUserEmail(data.email));
    }

    if (!response.error && !response.code) {
      let result = ArrayCodes.find((item) => item.code === response.code);
      showSuccess(result?.ru ?? response.message);
    }
  }
);

export const userSlice = createSlice({
  name: "userData",
  initialState: {
    adr: "",
    exp: undefined,
    id: undefined,
    fio: "",
    sys: "",
    status: "",
    statsStatus: "",
    rol: "",
    send_messages: null,
    subscription: {},
    telegram: "",
    nickname: undefined,
    font_size: 12,
    stats: [],
    ui_guide_completed: true,
    products: [],
    payment_status: "",
    avatar: "",
  },

  reducers: {
    clearUserData: (state) => {
      state.adr = "";
      state.exp = undefined;
      state.id = undefined;
      state.fio = "";
      state.rol = "";
      state.sys = "";
      state.nickname = undefined;
      state.status = "";
      state.statsStatus = "";
      state.telegram = "";
      state.payment_status = "";
      state.font_size = 12;
      state.send_messages = null;
      state.ui_guide_completed = true;
      state.subscription = {};
      state.stats = [];
      state.products = [];
      state.avatar = [];
      state.isMessagesLimit = null;
      Cookies.remove("analyticsToken");
      Cookies.remove("refresh_token");
    },
    updateUserData: (state, action) => {
      state.adr = action.payload?.adr || "";
      state.exp = action.payload?.exp;
      state.id = action.payload?.id;
      state.rol = action.payload?.rol;
      state.fio = action.payload?.fio || "";
      state.sys = action.payload?.sys || "";
    },
    updateUserEmail: (state, action) => {
      state.adr = action.payload || "";
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setFreeLimitFinished: (state, action) => {
      state.isMessagesLimit = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(auth.pending, (state, action) => {
      state.status = "authorization";
    });
    builder.addCase(auth.fulfilled, (state, action) => {
      state.ui_guide_completed = action.payload.ui_guide_completed ?? "false";
      state.status = "";
      state.isAfterLogin = true;
    });
    builder.addCase(register.pending, (state, action) => {
      state.status = "registration";
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.status = "";
    });
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.status = "reset";
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.status = "";
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.status = "reset";
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.status = "";
    });
    builder.addCase(oauthWithCode.pending, (state, action) => {
      state.status = "oauthAuthorization";
    });
    builder.addCase(oauthWithCode.fulfilled, (state, action) => {
      state.ui_guide_completed = action.payload.ui_guide_completed ?? "false";
      state.status = "";
      state.isAfterLogin = true;
    });
    builder.addCase(authWithCode.pending, (state, action) => {
      state.status = "oauthAuthorization";
    });
    builder.addCase(authWithCode.fulfilled, (state, action) => {
      state.ui_guide_completed = action.payload.ui_guide_completed ?? "false";
      state.status = "";
      state.isAfterLogin = true;
    });
    builder.addCase(getProfileInfo.pending, (state, action) => {
      state.status = "getProfileInfo";
    });
    builder.addCase(getProfileInfo.rejected, (state, action) => {
      state.nickname = "";
    });
    builder.addCase(getProfileInfo.fulfilled, (state, action) => {
      if (state.isAfterLogin) {
        if (action.payload?.subscription?.valid_till) {
          const validTillDate = new Date(
            action.payload?.subscription?.valid_till
          );
          const currentDate = new Date();
          if (currentDate < validTillDate) {
            window.ym(97182671, "reachGoal", "auth-with-subscription");
          }
        }
        state.isAfterLogin = false;
      }

      state.products = action.payload.products;
      state.payment_status = action.payload?.payment_status ?? "";
      state.avatar = action.payload.avatar;
      state.send_messages = action.payload.send_messages;
      state.telegram = action.payload.telegram;
      state.nickname = action.payload.nickname ?? "";
      state.isMessagesLimit = action.payload.messages_limit_finished ?? false;

      if (action.payload.name) {
        state.fio = action.payload.name ?? "";
      }

      if (action.payload.subscription) {
        let sub = action.payload.subscription;
        state.subscription = action.payload.subscription;
        state.subscription.today_messages = action.payload.today_messages;

        if (
          sub.units === "days" &&
          action.payload.today_messages >= sub.messages_limit
        ) {
          state.isMessagesLimit = true;
        }
      }

      state.status = "";
    });
    builder.addCase(setProfileInfo.fulfilled, (state, action) => {
      if (action.payload.subscription) {
        state.subscription = action.payload.subscription;
      }
      if (action.payload.products) {
        state.products = action.payload.products;
      }
      state.send_messages = action.payload.send_messages;
      state.telegram = action.payload.telegram;
      state.nickname = action.payload.nickname;
      state.fio = action.payload.name;
      state.status = "";
    });
    builder.addCase(completeGuide.fulfilled, (state, action) => {
      state.ui_guide_completed = true;
    });
    builder.addCase(sentPayment.pending, (state, action) => {
      state.status = "getting payment";
    });
    builder.addCase(sentPayment.fulfilled, (state, action) => {
      state.status = "";
    });

    builder.addCase(getProfileStats.pending, (state, action) => {
      state.statsStatus = "getting stats";
    });
    builder.addCase(getProfileStats.fulfilled, (state, action) => {
      state.statsStatus = "";
      if (Array.isArray(action.payload)) {
        state.stats = action.payload;
      }
    });
  },
});

export const {
  clearUserData,
  updateUserData,
  updateUserEmail,
  setStatus,
  setFreeLimitFinished,
} = userSlice.actions;

export default userSlice.reducer;
