import React from "react";
import { useSelector } from "react-redux";
import Authorization from "components/pages/Authorization";
import Registration from "components/pages/Registration";
import AccountExit from "components/common/AccountExit";
import ForgotPass from "components/common/ForgotPass";
import ResetPass from "components/common/ResetPass";

function ModalOutlet() {
  const { modal } = useSelector((store) => store.system);

  switch (modal) {
    case "Authorization":
      return <Authorization />;
    case "Registration":
      return <Registration />;
    case "AccountExit":
      return <AccountExit />;
    case "ForgotPass":
      return <ForgotPass />;
    case "ResetPass":
      return <ResetPass />;
    default:
      return null;
  }
}

export default ModalOutlet;
