import Cookies from "js-cookie";
import moment from "moment";
import { get } from "utils/api";

import { clearUserData, updateUserData } from "store/slices/userSlice";
import { setMaintenanceMode } from "store/slices/systemSlice";
import { decodeToken } from "./decodeToken";

let refreshTokenPromise = null;
let isError = false;

const jwtRefreshMiddleware = ({ dispatch }) => {
  return (next) => async (action) => {
    if (
      action?.payload?.code &&
      (action?.payload?.code === 1 || action?.payload?.code === 126)
    ) {
      dispatch(setMaintenanceMode(true));
      return;
    }

    if (typeof action === "function") {
      const accessToken = Cookies.get("analyticsToken");
      if (accessToken) {
        const decoded = decodeToken(accessToken);
        if (!decoded || !decoded.exp) {
          dispatch(clearUserData());
          return;
        }
        if (decoded.exp && moment().utc().unix() > decoded.exp) {
          if (!refreshTokenPromise) {
            refreshTokenPromise = get("/refresh")
              .then((response) => {
                if (!response?.access_token) {
                  throw new Error(response);
                }
                const access_token = response?.access_token;
                Cookies.set("analyticsToken", access_token);

                const parsedAccessToken = decodeToken(access_token);
                dispatch(updateUserData(parsedAccessToken));
                refreshTokenPromise = null;
              })
              .catch((error) => {
                dispatch(clearUserData());
                refreshTokenPromise = null;
                isError = true;
              });
          }

          await refreshTokenPromise;
          if (isError) {
            return;
          }
          return next(action);
        }
      }
    }

    return next(action);
  };
};

export default jwtRefreshMiddleware;
