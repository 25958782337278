import React, { useEffect, useState, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./Modal.css";
import { Transition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "store/slices/systemSlice";
import CloseSvg from "components/common/svgs/CloseSvg";
import ModalOutlet from "./components/ModalOutlet";
import Cookies from "js-cookie";

const Modal = () => {
  const isModalOpen = useSelector((store) => store.system.isModalOpen);
  const [active, setActive] = useState(false);
  const [styles, setStyles] = useState({});
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken = Cookies.get("analyticsToken");

  useEffect(() => {
    if (isModalOpen) {
      setActive(true);
      document.body.style.overflow = "hidden";

      const modalElement = modalRef.current;
      const styles2 = window.getComputedStyle(modalElement.children[0]);
      setStyles({ maxWidth: styles2.maxWidth, width: "100%" });
    }
  }, [isModalOpen]);

  const handleSetActive = () => {
    if (accessToken) {
      setActive(false);
    }
  };

  const handleCloseModal = () => {
    const params = new URLSearchParams(location.search);
    const codeParam = params.get("code");

    dispatch(closeModal());
    document.body.style.overflow = "";
    if (codeParam) {
      navigate("/");
    }
  };

  if (!isModalOpen) {
    return "";
  }

  return (
    <Transition
      in={active}
      timeout={active ? 1 : 200}
      onExited={handleCloseModal}
    >
      {(state) => (
        <div className={`Modal ${state}`} onClick={handleSetActive}>
          <div className="Modal__wrap">
            <div
              className="Modal__inner"
              onClick={(e) => e.stopPropagation()}
              ref={modalRef}
              style={styles}
            >
              <ModalOutlet />
              {accessToken && (
                <button className="Modal__controls" onClick={handleSetActive}>
                  <CloseSvg />
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default Modal;
