import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { Delete, get } from "utils/api";
import { showError } from "utils";
import ArrayCodes from "ArrayCodes.json";
import { addMessage } from "./chatSlice";
import moment from "moment";

export const getThreadFiles = createAsyncThunk(
  "threads/getThreadFiles",
  async (thread_id) => {
    const response = await get(`/thread/files/${thread_id}`);
    return response;
  }
);

export const uploadFile = createAsyncThunk(
  "threads/uploadFile",
  async ({ files }, { getState, dispatch }) => {
    const userToken = Cookies.get("analyticsToken");
    const state = getState();
    const thread_id = state.threadsData.activeThreadId;

    const data = new FormData();
    for (const file of files) {
      data.append("file", file);
    }
    data.append("thread_id", thread_id);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}${"/assistants/files"}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: data,
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      let result = ArrayCodes.find((item) => item.code === responseData.code);
      showError(
        result.ru ?? response.error ?? "Неверный статус ответа от сервера"
      );
    }

    if (responseData.length >= 1) {
      for (let i = 0; i < responseData.length; i++) {
        const file = responseData[i];
        const messageData = {
          id: moment(file.created_at).valueOf(),
          object: "system message",
          created_at: moment(file.created_at).valueOf() / 1000,
          prompt: `Загружен файл ${file.file_name}.`,
          role: "system",
          thread_id: file.thread_id,
        };
        dispatch(addMessage(messageData));
      }
    }
    return responseData;
  }
);

export const deleteFile = createAsyncThunk(
  "threads/deleteFile",
  async ({ file }) => {
    const response = await Delete(`/file/${file.file_id}`);

    return file;
  }
);

export const fileSlice = createSlice({
  name: "filesData",
  initialState: {
    files: [],
    status: "",
  },

  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFile.pending, (state, action) => {
      state.status = "uploading";
    });
    builder.addCase(uploadFile.rejected, (state, action) => {
      state.status = "";
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.status = "";
      if (action.payload?.length >= 1) {
        for (const file of action.payload) {
          state.files.push(file);
        }
      }
    });
    builder.addCase(deleteFile.pending, (state, action) => {
      state.status = "deleting";
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      let copy = state.files.filter(
        (file) => file.file_id !== action.payload.file_id
      );
      state.files = copy;
      state.status = "";
    });
    builder.addCase(getThreadFiles.fulfilled, (state, action) => {
      if (Array.isArray(action.payload)) {
        state.files = action.payload;
      }
    });
  },
});

export const { setStatus } = fileSlice.actions;

export default fileSlice.reducer;
