import Cookies from "js-cookie";
import { showError } from "utils";
import ArrayCodes from "../ArrayCodes.json";

export const get = async (url, params = "") => {
  try {
    const localToken = Cookies.get("analyticsToken");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}${url}?${params}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: localToken,
        },
      }
    );

    const data = await response.json();
    if (!response.ok) {
      let result = ArrayCodes.find((item) => item.code === data.code);
      showError(result?.ru ?? data.error ?? data.message);
      console.error(data.error);
    }

    return data;
  } catch (error) {
    return error.message;
  }
};

// Функция для отправки POST запросов
export const post = async (url, data = {}) => {
  try {
    const localToken = Cookies.get("analyticsToken");
    const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: localToken,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let result = ArrayCodes.find((item) => item.code === responseData.code);
      showError(result?.ru ?? responseData.error ?? responseData.message);
      console.error(responseData.error);
    }

    return responseData;
  } catch (error) {
    return error.message;
  }
};

// Функция для отправки PUT запросов
export const Put = async (url, data = {}) => {
  try {
    const localToken = Cookies.get("analyticsToken");
    const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: localToken,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();

    if (!response.ok) {
      let result = ArrayCodes.find((item) => item.code === responseData.code);

      showError(result?.ru ?? responseData.error);
    }

    return responseData;
  } catch (error) {
    return error.message;
  }
};

// Функция для отправки DELETE запросов
export const Delete = async (url, params = "") => {
  try {
    const localToken = Cookies.get("analyticsToken");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}${url}?${params}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: localToken,
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      let result = ArrayCodes.find((item) => item.code === data.code);

      showError(result?.ru ?? data.error ?? data.message);
    }

    return data;
  } catch (error) {
    return error.message;
  }
};
