import jwt_decode from "jwt-decode";
import { showError } from "utils";

export const decodeToken = (token) => {
  try {
    const decodedToken = jwt_decode(token);

    if (!decodedToken.rol) {
      throw new Error();
    }

    return decodedToken;
  } catch (error) {
    console.error("Ошибка декодирования токена:", error.message);
    showError("Ошибка декодирования токена");
    return null;
  }
};
