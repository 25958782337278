import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isSidebarOpen: JSON.parse(localStorage.getItem("isSidebarOpen")) ?? false,
  isModalOpen: false,
  modal: "",
  maintenanceMode: false,
  isUiGuideActive: false,
  blackout: false,
};

export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isModalOpen = true;
      state.modal = action.payload;
    },
    closeModal: (state, action) => {
      state.isModalOpen = false;
      document.body.style.overflow = "";
      state.modal = "";
    },
    setSidebarState: (state, action) => {
      state.isSidebarOpen = action.payload;
      localStorage.setItem("isSidebarOpen", action.payload);
    },
    setMaintenanceMode: (state, action) => {
      state.maintenanceMode = action.payload;
    },
    setUiGuideState: (state, action) => {
      state.isUiGuideActive = action.payload;
    },
    toggleBlackoutState: (state, action) => {
      if (action.payload !== undefined) {
        state.blackout = action.payload;
      } else {
        state.blackout = !state.blackout;
      }
    },
  },
});

export const {
  openModal,
  closeModal,
  setSidebarState,
  setMaintenanceMode,
  setUiGuideState,
  toggleBlackoutState,
} = systemSlice.actions;

export default systemSlice.reducer;
