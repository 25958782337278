import React, { useEffect, useState } from "react";
import "./Registration.css";
import PasswordInput from "components/common/passwordInput";
import { register } from "../../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "store/slices/systemSlice";
import { passwordValidation } from "utils/passwordValidation";

const Registration = () => {
  const [firstName, setFirstName] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [nicknameDirty, setNicknameDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);
  const [firstNameError, setFirstNameError] = useState(
    "Поле не может быть пустым"
  );
  const [nicknameError, setNicknameError] = useState(
    "Поле не может быть пустым"
  );
  const [emailError, setEmailError] = useState("Поле не может быть пустым");
  const [passwordError, setPasswordError] = useState(
    "Поле не может быть пустым"
  );
  const [confirmPasswordError, setConfirmPasswordError] = useState(
    "Поле не может быть пустым"
  );
  const [formValid, setFormValid] = useState(false);

  const status = useSelector((state) => state.userData.status);

  const dispatch = useDispatch();

  const isLoading = status === "registration";

  useEffect(() => {
    if (
      firstNameError ||
      nicknameError ||
      emailError ||
      passwordError ||
      confirmPasswordError
    ) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [
    firstNameError,
    nicknameError,
    emailError,
    passwordError,
    confirmPasswordError,
  ]);

  const submitAuthForm = (e) => {
    e.preventDefault();
    const data = {
      nickname: nickname,
      firstName: firstName,
      email: email,
      password: password,
    };
    dispatch(register({ data })).then(() => {
      // dispatch(closeModal());
      dispatch(openModal("Authorization"));
    });
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "firstName":
        const nameRegex = /^[A-Za-zА-ЯЁа-яё]+$/;
        setFirstName(e.target?.value);

        if (!nameRegex.test(String(e.target.value).toLowerCase())) {
          setFirstNameError("Некорректное имя");
        } else if (e.target?.value.length < 3 || e.target?.value.length > 20) {
          setFirstNameError("Имя должно быть больше 3 и меньше 20 символов");
        } else {
          setFirstNameError("");
        }
        break;
      case "nickname":
        setNickname(e.target?.value);
        if (e.target?.value.length < 3 || e.target?.value.length > 20) {
          setNicknameError("Никнейм должен быть больше 3 и меньше 20 символов");
        } else {
          setNicknameError("");
        }
        break;
      case "email":
        const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        setEmail(e.target.value);

        if (!regex.test(String(e.target.value).toLowerCase())) {
          setEmailError("Некорректный email");
        } else {
          setEmailError("");
        }
        break;
      case "password":
        setPassword(e.target?.value);
        setPasswordError();
        passwordValidation(
          e,
          setPasswordError,
          true,
          password,
          confirmPassword,
          setPasswordError,
          setConfirmPasswordError
        );
        break;
      case "confirmPassword":
        setConfirmPassword(e.target?.value);
        setConfirmPasswordError();
        passwordValidation(
          e,
          setConfirmPasswordError,
          true,
          password,
          confirmPassword,
          setPasswordError,
          setConfirmPasswordError
        );
        break;
      default:
        break;
    }
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "firstName":
        setFirstNameDirty(true);
        break;
      case "password":
        setPasswordDirty(true);
        break;
      case "confirmPassword":
        setConfirmPasswordDirty(true);
        break;
      case "nickname":
        setNicknameDirty(true);
        break;
      case "email":
        setEmailDirty(true);
        break;
      default:
        break;
    }
  };
  const isPasswordError = passwordDirty && passwordError;
  const isConfirmPasswordError = confirmPasswordDirty && confirmPasswordError;

  return (
    <div className={`Auth`}>
      <div className="Auth__wrap">
        <div className="Auth__body">
          <div className="Auth__title title_h3">Регистрация</div>
          <div className="Auth__subTitle text">
            Присоединяйтесь к нам и используйте новые возможности.
          </div>
          <form className="Auth__inputBlock" onSubmit={submitAuthForm}>
            <div className="Auth__input Auth__input_name">
              <input
                name="firstName"
                className={`Input ${
                  firstNameDirty && firstNameError ? "Error" : ""
                }`}
                type="text"
                placeholder="Имя"
                value={firstName}
                onBlur={blurHandler}
                onChange={handleInputChange}
              />
              {firstNameDirty && firstNameError && (
                <span className="Validation__Error">{firstNameError}</span>
              )}
            </div>
            <div className="Auth__input Auth__input_nickname">
              <input
                name="nickname"
                className={`Input ${
                  nicknameDirty && nicknameError ? "Error" : ""
                }`}
                type="text"
                placeholder="Псевдоним"
                value={nickname}
                onBlur={blurHandler}
                onChange={handleInputChange}
              />
              {nicknameDirty && nicknameError && (
                <span className="Validation__Error">{nicknameError}</span>
              )}
            </div>
            <div className="Auth__input">
              <input
                name="email"
                className={`Input ${emailDirty && emailError ? "Error" : ""}`}
                type="email"
                placeholder="Ваш email"
                value={email}
                onBlur={blurHandler}
                onChange={handleInputChange}
              />
              {emailDirty && emailError && (
                <span className="Validation__Error">{emailError}</span>
              )}
            </div>
            <div className="Auth__passwordWrap">
              <PasswordInput
                name="password"
                text={"Пароль"}
                className={`Auth__input ${isPasswordError ? "Error" : ""}`}
                value={password}
                onChange={(e) => handleInputChange(e)}
                onBlur={blurHandler}
              />
              {isPasswordError && (
                <span className="Validation__Error">{passwordError}</span>
              )}
            </div>
            <div className="Auth__passwordWrap">
              <PasswordInput
                name="confirmPassword"
                text={"Повторите пароль"}
                className={`Auth__input ${
                  isConfirmPasswordError ? "Error" : ""
                }`}
                value={confirmPassword}
                onChange={(e) => handleInputChange(e)}
                onBlur={blurHandler}
              />
              {isConfirmPasswordError && (
                <span className="Validation__Error">
                  {confirmPasswordError}
                </span>
              )}
            </div>

            <button
              className="Auth__inputBtn SolidBtn"
              type="submit"
              disabled={!formValid || isLoading}
            >
              {isLoading ? (
                <div className="loader"></div>
              ) : (
                "Зарегистрироваться"
              )}
            </button>
          </form>
          <div className="Auth__desc">
            Регистрируясь, я соглашаюсь с{" "}
            <span className="Auth__desc_link link">
              Правилами и ограничениями
            </span>
          </div>
          <div className="Auth__authbtn">
            Уже зарегистрированы?{" "}
            <span onClick={() => dispatch(openModal("Authorization"))}>
              Войти
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
