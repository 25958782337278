export const passwordValidation = (
  e,
  setError,
  needConfirm = false,
  password,
  confirmPassword,
  setPasswordError,
  setConfirmPasswordError
) => {
  const value = e.target.value;
  const passwordRegex = /^(?=.*[A-ZА-ЯЁ])(?=.*[a-zа-яё]).*$/;

  if (value.length < 8 || value.length > 20) {
    setError("Пароль должен содержать от 8 до 20 символов");
  }

  if (!passwordRegex.test(value)) {
    setError(
      "Пароль должен содержать хотя бы одну заглавную и одну строчную буквы"
    );
  }
  if (needConfirm) {
    switch (e.target.name) {
      case "confirmPassword":
        if (value !== password) {
          setError("Пароли должны совпадать");
        } else if (
          password.length >= 8 &&
          password.length <= 20 &&
          passwordRegex.test(password)
        ) {
          setPasswordError();
        }
        break;
      case "password":
        if (confirmPassword !== value && confirmPassword.length > 1) {
          setConfirmPasswordError("Пароли должны совпадать");
        } else if (confirmPassword.length > 1) {
          setConfirmPasswordError();
        }
        break;
      default:
        break;
    }
  }
};
