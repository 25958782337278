import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { closeModal, openModal } from "store/slices/systemSlice";
import { forgotPassword } from "store/slices/userSlice";

function ForgotPass() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("Поле не может быть пустым");
  const [emailDirty, setEmailDirty] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const status = useSelector((state) => state.userData.status);
  const dispatch = useDispatch();

  const isLoading = status === "reset";

  const emailHandler = (e) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    setEmail(e.target.value);

    if (!regex.test(String(e.target.value).toLowerCase())) {
      setEmailError("Некорректный email");
    } else {
      setEmailError("");
    }
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "email":
        setEmailDirty(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (emailError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [emailError]);

  const submitAuthForm = (e) => {
    e.preventDefault();
    dispatch(forgotPassword({ email, dispatch })).then(() => {
      // dispatch(closeModal());
      dispatch(openModal("Authorization"));
    });
  };

  return (
    <div className={`Auth`}>
      <div className="Auth__wrap">
        <div className="Auth__body">
          <div className="Auth__title title_h3">Сброс пароля</div>
          <div className="Auth__subTitle text">
            Пожалуйста, введите почту которую вы указывали при регистрации.
          </div>
          <form className="Auth__inputBlock" onSubmit={submitAuthForm}>
            <input
              name="email"
              className={`Input ${emailDirty && emailError ? "Error" : ""}`}
              type="email"
              placeholder="Ваш email"
              value={email}
              onChange={emailHandler}
              onBlur={blurHandler}
            />
            {emailDirty && emailError && (
              <span className="Validation__Error">{emailError}</span>
            )}
            <input
              className="Input"
              type={"password"}
              style={{ display: "none" }}
            />
            <button
              className="Auth__inputBtn SolidBtn"
              type="submit"
              disabled={!formValid || isLoading}
            >
              {isLoading ? <div className="loader"></div> : "Сбросить"}
            </button>
          </form>
          <div className="Auth__authbtn ">
            Вспомнили пароль?{" "}
            <span
              className="link"
              onClick={() => dispatch(openModal("Authorization"))}
            >
              Авторизоваться
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPass;
