import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import systemReducer from "./slices/systemSlice";
import { composeWithDevTools } from "@redux-devtools/extension";
import adminSlice from "./slices/adminSlice";
import jwtRefreshMiddleware from "utils/jwtRefreshMiddleware";
import threadSlice from "./slices/threadSlice";
import fileSlice from "./slices/fileSlice";
import chatSlice from "./slices/chatSlice";

export const store = configureStore({
  reducer: {
    userData: userSlice,
    filesData: fileSlice,
    chatData: chatSlice,
    system: systemReducer,
    adminData: adminSlice,
    threadsData: threadSlice,
  },
  composeWithDevTools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(jwtRefreshMiddleware),
});
