import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Delete, Put, get, post } from "utils/api";

export const getStats = createAsyncThunk("user/getStats", async () => {
  const response = await get(`/messages/stats`);
  if (Array.isArray(response)) {
    return response;
  }
});

export const getPrompts = createAsyncThunk(
  "admin/getPrompts",
  async (dispatch) => {
    const response = await get(`/prompts`);
    if (Array.isArray(response)) {
      dispatch(setElmts(response));
    }
  }
);

export const addEl = createAsyncThunk(
  "admin/addEl",
  async ({ data, dispatch }) => {
    const response = await post(`/prompt`, data);
    if (response && !response.message) {
      dispatch(add(response));
    }
    return;
  }
);

export const deleteEl = createAsyncThunk(
  "admin/deleteEl",
  async ({ el, dispatch }) => {
    const response = await Delete(`/prompt/${el}`);
    if (response) {
      dispatch(del(el));
    }
    return;
  }
);

export const editEl = createAsyncThunk(
  "admin/editEl",
  async ({ data, index, dispatch }) => {
    const response = await Put(`/prompt`, data);
    if (response) {
      dispatch(edit({ data, index }));
    }
    return;
  }
);

export const adminSlice = createSlice({
  name: "adminData",
  initialState: {
    data: [],
    stats: [],
    status: "",
  },

  reducers: {
    del: (state, action) => {
      let arrayOfObjects = state.data.filter(
        (obj) => obj.ID !== action.payload
      );
      state.data = arrayOfObjects;
    },
    add: (state, action) => {
      state.data.push(action.payload);
    },
    edit: (state, action) => {
      let index = action.payload.index;
      let data = action.payload.data;
      if (state.data[index].ID === data.ID) {
        state.data[index] = data;
      }
    },
    setElmts: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrompts.pending, (state, action) => {
      state.status = "getting";
    });
    builder.addCase(getPrompts.fulfilled, (state, action) => {
      state.status = "";
    });
    builder.addCase(addEl.pending, (state, action) => {
      state.status = "adding";
    });
    builder.addCase(addEl.fulfilled, (state, action) => {
      state.status = "";
    });
    builder.addCase(deleteEl.pending, (state, action) => {
      state.status = "adding";
    });
    builder.addCase(deleteEl.fulfilled, (state, action) => {
      state.status = "";
    });
    builder.addCase(editEl.pending, (state, action) => {
      state.status = "adding";
    });
    builder.addCase(editEl.fulfilled, (state, action) => {
      state.status = "";
    });
    builder.addCase(getStats.fulfilled, (state, action) => {
      state.stats = action.payload;
    });
  },
});

export const { del, setElmts, add, edit } = adminSlice.actions;

export default adminSlice.reducer;
