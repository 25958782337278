import * as React from "react";
const CloseSvg = ({
  width = 24,
  height = 24,
  color = "#212931",
  size = "regular",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      style={{ minWidth: width }}
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d={size === "regular" ? "m5 5 14 14M19 5 5 19" : "m7 7 10 10M17 7 7 17"}
      />
    </svg>
  );
};
export default CloseSvg;
