import { React, Route, Routes, useNavigate } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUserData,
  getProfileInfo,
  updateUserData,
} from "store/slices/userSlice";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import PrivateRoutes from "utils/PrivateRoutes";
import Cookies from "js-cookie";
import CloseSvg from "components/common/svgs/CloseSvg";
import Modal from "components/common/modal";
import { decodeToken } from "utils/decodeToken";

const Home = lazy(() => import("components/pages/Home"));
const Main = lazy(() => import("components/pages/Main"));
const InvalidPage = lazy(() =>
  import("components/pages/InvalidPage/InvalidPage")
);
const Stats = lazy(() => import("components/pages/Stats"));
const Admin = lazy(() => import("components/pages/Admin"));
const Threads = lazy(() => import("components/pages/Threads"));
const Reference = lazy(() => import("components/pages/Reference"));
const FAQ = lazy(() => import("components/pages/FAQ"));
const Profile = lazy(() => import("components/pages/Profile"));
const ProfilePage = lazy(() =>
  import("components/pages/Profile/Components/ProfilePage")
);

function App() {
  const rol = useSelector((state) => state.userData.rol);
  const accessToken = Cookies.get("analyticsToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const isResetLocation =
      window.location.pathname.includes("/reset") ||
      window.location.pathname.includes("/verify") ||
      window.location.pathname.includes("/login");

    if (accessToken && rol?.length < 1 && !isResetLocation) {
      try {
        const userTokenParsed = decodeToken(accessToken);
        dispatch(updateUserData(userTokenParsed));
      } catch (error) {
        dispatch(clearUserData());
        navigate("/");
      }
    }

    if (accessToken && !window.location.pathname.includes("/profile")) {
      dispatch(getProfileInfo());
    }

    if (!accessToken && rol.length > 1 && !isResetLocation) {
      dispatch(clearUserData());
      navigate("/");
    }
  }, [accessToken]);

  const CloseButton = ({ closeToast }) => (
    <CloseSvg
      onClick={closeToast}
      className="toastify__close"
      color="#fff"
      size="small"
    />
  );

  return (
    <div className="app">
      <Modal />
      <Routes>
        {/* {!accessToken ? (
          <Route
            path="/"
            element={
              <Suspense>
                <Home />
              </Suspense>
            }
          />
        ) : (
          ""
        )} */}
        <Route
          path="/"
          element={<PrivateRoutes neededRol={["", "user", "admin"]} />}
        >
          {["/home", "/reset", "/verify", "/:type/login"].map((path, index) => {
            return (
              <Route
                path={path}
                element={
                  <Suspense>
                    <Main />
                  </Suspense>
                }
                key={index}
              />
            );
          })}
          <Route
            path="/"
            element={
              <Suspense>
                <Main />
              </Suspense>
            }
          >
            <Route path="d/*" element={<Threads />} />
            <Route path="profile" element={<Profile />}>
              <Route path="" element={<ProfilePage />} />
              <Route path="reference" element={<Reference />} />
              <Route path="FAQ" element={<FAQ />} />
              <Route element={<PrivateRoutes neededRol={["admin"]} />}>
                <Route path="stats" element={<Stats />} />
                <Route path="admin" element={<Admin />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<InvalidPage />} />
      </Routes>
      <ToastContainer closeButton={CloseButton} />
    </div>
  );
}

export default App;
