import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { openModal } from "store/slices/systemSlice";
import { resetPassword } from "store/slices/userSlice";
import { useLocation } from "react-router";
import PasswordInput from "./passwordInput";
import { passwordValidation } from "utils/passwordValidation";

function ResetPass() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(
    "Поле не может быть пустым"
  );
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(
    "Поле не может быть пустым"
  );
  const [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const status = useSelector((state) => state.userData.status);

  const isLoading = status === "reset";

  useEffect(() => {
    if (confirmPasswordError || passwordError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [confirmPasswordError, passwordError]);

  const submitAuthForm = (e) => {
    const params = new URLSearchParams(location.search);
    const codeParam = params.get("code");

    e.preventDefault();
    if (codeParam) {
      dispatch(
        resetPassword({
          data: { code: codeParam, pwd1: password, pwd2: confirmPassword },
        })
      ).then((res) => {
        if (!res.payload.error) {
          dispatch(openModal("Authorization"));
        }
      });
    }
  };

  const onPasswordChange = (e, setValue, setError) => {
    const value = e.target.value;
    setValue(value);
    setError();
    passwordValidation(
      e,
      setError,
      true,
      password,
      confirmPassword,
      setPasswordError,
      setConfirmPasswordError
    );
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "password":
        setPasswordDirty(true);
        break;
      case "confirmPassword":
        setConfirmPasswordDirty(true);
        break;
      default:
        break;
    }
  };
  const isPasswordError = passwordDirty && passwordError;
  const isConfirmPasswordError = confirmPasswordDirty && confirmPasswordError;

  return (
    <div className={`Auth`}>
      <div className="Auth__wrap">
        <div className="Auth__body">
          <div className="Auth__title title_h3">Сброс пароля</div>
          <div className="Auth__subTitle text">
            Пожалуйста, укажите новый пароль для профиля.
          </div>
          <form className="Auth__inputBlock" onSubmit={submitAuthForm}>
            <div className="Auth__passwordWrap">
              <PasswordInput
                name={"password"}
                text={"Пароль"}
                className={
                  isPasswordError ? "Auth__input Error" : "Auth__input"
                }
                value={password}
                onChange={(e) =>
                  onPasswordChange(e, setPassword, setPasswordError)
                }
                onBlur={blurHandler}
              />
              {isPasswordError && (
                <span className="Validation__Error">{passwordError}</span>
              )}
            </div>
            <div className="Auth__passwordWrap">
              <PasswordInput
                name={"confirmPassword"}
                text={"Повторите пароль"}
                className={
                  isConfirmPasswordError ? "Auth__input Error" : "Auth__input"
                }
                value={confirmPassword}
                onChange={(e) =>
                  onPasswordChange(
                    e,
                    setConfirmPassword,
                    setConfirmPasswordError
                  )
                }
                onBlur={blurHandler}
              />
              {isConfirmPasswordError && (
                <span className="Validation__Error">
                  {confirmPasswordError}
                </span>
              )}
            </div>
            <button
              className="Auth__inputBtn SolidBtn"
              type="submit"
              disabled={!formValid || isLoading}
            >
              {isLoading ? <div className="loader"></div> : "Сохранить"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPass;
